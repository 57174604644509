<template>
  <a-spin :spinning="loading">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-center">
        <h2 class="mr-3">Base Ruangan</h2>
      </div>
      <!-- <div>
        <a-button @click.prevent="" type="danger">
          <LeftSquareOutlined />
          Batal</a-button
        >
      </div> -->
    </div>
    <a-card>
      <a-row class="mt-4" :gutter="[32, 32]">
        <a-col :span="24" class="d-flex justify-content-between align-items-center">
          <a-button @click="$router.back()">
            <LeftOutlined />Back
          </a-button>
          <!-- <a-button @click="changeEdittable">
            <span v-if="!editable"> <FormOutlined class="mr-2" />Edit </span>
            <span v-else> <EyeOutlined class="mr-2" />View </span>
          </a-button> -->
        </a-col>
        <a-col :span="8">
          <div class="mb-5">
            <div>
              <h1 class="text-title">Customer</h1>
            </div>
            <div>
              <a-row :gutter="[24, 12]">
                <a-col :sm="{ span: 24 }">
                  <a-select placeholder="Pilih Customer" class="w-100" v-model:value="dataPayload.customerId"
                    @change="handleChangeCustomerId">
                    <a-select-option v-for="(item, index) in dataCustomers" :key="index" :value="item.id">{{ item.name
                    }}</a-select-option>
                  </a-select>
                </a-col>
              </a-row>
            </div>
          </div>
          <div class="mb-5">
            <div>
              <h1 class="text-title">Lokasi</h1>
            </div>
            <div>
              <a-row :gutter="[24, 12]">
                <a-col :sm="{ span: 24 }">
                  <a-select placeholder="Pilih Lokasi" class="w-100" v-model:value="dataPayload.locationId">
                    <a-select-option v-for="(item, index) in dataLocations" :key="index" :value="item.id">{{ item.name
                    }}</a-select-option>
                  </a-select>
                </a-col>
              </a-row>
            </div>
          </div>
          <div class="mb-5">
            <div>
              <h1 class="text-title">Kategori Ruangan</h1>
            </div>
            <div>
              <a-row :gutter="[24, 12]">
                <a-col :sm="{ span: 24 }">
                  <a-select placeholder="Pilih Kategori Ruangan" class="w-100" v-model:value="dataPayload.type">
                    <a-select-option v-for="(item, index) in dataRoomCat" :key="index" :value="item">{{ item
                    }}</a-select-option>
                  </a-select>
                </a-col>
              </a-row>
            </div>
          </div>
          <div class="mb-5">
            <div>
              <h1 class="text-title">Nama Ruangan</h1>
            </div>
            <div>
              <a-row :gutter="[24, 12]">
                <a-col :sm="{ span: 24 }">
                  <a-input placeholder="Masukkan Nama Ruangan" v-model:value="dataPayload.name"></a-input>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
        <a-col :span="24">
          <a-row :gutter="[24, 12]">
            <a-col :span="18"></a-col>
            <a-col :span="6" class="d-flex justify-content-end align-items-center" style="gap:15px">
              <a-button @click="$router.back()" type="primary">Cancel</a-button>
              <a-button @click="handleSubmit">Submit</a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-card>
  </a-spin>
</template>

<script>
import { onMounted, ref, createVNode, computed, reactive, onBeforeMount } from 'vue'
import { defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  FormOutlined,
  EyeOutlined,
} from '@ant-design/icons-vue'
import { message, Modal, notification } from 'ant-design-vue'
import { getOne, remove, getAllPendings, update } from '@/services/axios/brivice-backend'
import apiClient from '@/services/axios'
import assetsFile from '@/data/modc_dump.json'
import moment from 'moment'
import { uniqueId } from 'lodash'
import {
  createRooms,
  getCustomers,
  getLocationByCustomerId,
  getRoomByCustomerId,
} from '../../../services/axios/modc/customer'

export default {
  components: { LeftOutlined },
  setup() {
    let store = useStore()
    let route = useRoute()
    let router = useRouter()
    const assets = ref()
    const manufactures = ref()
    const loading = ref(false)
    const catFacility = ref('Access Point')
    const facility = ref('Access-002')
    const dataRoomCat = [
      'Server Room',
      'Network Room',
      'Storage Room',
      'Battery/UPS Room',
      'Genset Room',
      'Utility Room',
      'Power Distribution',
      'Entrance Room',
      'Public Network Area',
      'Testing Area',
      'Monitoring Room',
      'Meeting Room',
      'Operations Center',
    ]
    const dataPayload = ref({
      customerId: null,
      locationId: null,
      name: null,
      type: null,
    })
    const dataCustomers = ref([])
    const dataLocations = ref([])

    onBeforeMount(() => {
      fetchData()
    })
    const fetchData = async () => {
      const data = await getCustomers()
      dataCustomers.value = data.customer
      console.log(data, '<<<')
    }
    const handleChangeCustomerId = async e => {
      console.log(e, '<<<<')
      const data = await getLocationByCustomerId(e)
      dataLocations.value = data.locations
    }

    const handleSubmit = async () => {
      const data = await createRooms(dataPayload.value)
      if (data) {
        notification.success({
          message: 'Berhasil Submit',
          description: 'Data Room Berhasil di Submit',
        })
      } else {
        notification.error({
          message: 'Server Error',
          description: 'Internal Server Error',
        })
      }

      router.push({ name: 'Base Ruangan' })
    }
    return {
      loading,
      // assetsFile,
      // assets,
      // getAssets,
      // getManufacture,
      // manufactures,
      handleChangeCustomerId,
      // detail troubleshoot
      facility,
      catFacility,
      dataRoomCat,
      dataPayload,
      dataCustomers,
      dataLocations,
      // Last Section

      // editable,

      // getCurrentDate,
      // changeEdittable,

      handleSubmit,
      // dataSource,
    }
  },
}
</script>
<style lang="scss">
@import '@/views/styleViews.scss';

.text-title {
  font-size: 1.5rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-subtitle {
  font-size: 1.3rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-label {
  font-size: 1rem;
  color: $text;
  font-weight: 400;
  margin-bottom: 10px;
}

// . {
//   background-color: white !important;
//   input {
//     background-color: white !important;
//   }
// }
// :global(.ant-select-.ant-select:not(.ant-select-customize-input)) {
//   :global(.ant-select-selector) {
//     background-color: white !important;
//   }
// }
// .ant-input .ant- {
//   background-color: #ffffff !important;
// }
.th-upload-wrapper {
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;

    .ant-upload {
      width: 300px;
      height: 100px;
    }
  }
}
</style>
